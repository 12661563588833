<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="29">
        <el-col :span="12">
          <div class="money_l">
            <div class="left">
              <div class="amount">账户总金额</div>
              <div class="price">312,356.00元</div>
            </div>
            <div class="right">
              <div>含冻结金额：10,000.00元</div>
            </div>
            <div class="space"></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="money_r">
            <div class="left">
              <div class="amount">可提现金额</div>
              <div class="price">312,356.00元</div>
            </div>
            <div class="right_r">
              <div>收入金额：12,356.00元</div>
              <div>充值金额：10,000.00元</div>
            </div>
            <div class="space_r"></div>
          </div>
        </el-col>
      </el-row>

      <div class="surewithdrawal">
        <div class="withdrawal_input">
          <div class="withdrawal_input_text">提现金额：</div>
          <el-input v-model="input" clearable placeholder="请输入提现金额"></el-input>
          <div class="yuan">元</div>
        </div>
        <div class="submit"><el-button type="primary" size="medium">确定提现</el-button></div>
      </div>

      <div class="explain">
        <div class="explain_title">提现说明：</div>
        <div>1.当前提现功能暂未开放，请线下联系客服进行提现</div>
        <div>2.提现时效为1~3个工作日，节假日可能会延期</div>
        <div>提现流程：①线下发送提现请求——②开具发票——③线下打款</div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: '',
    }
  },
  created() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.box-card {
  padding-bottom: 200px;
  .money_l,
  .money_r {
    display: flex;
    height: 162px;
    border: 1px solid #e2e2e2;
    padding-left: 38px;
    padding-top: 55px;
    box-sizing: border-box;
    position: relative;
  }
  .space {
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 162px;
    background: #f8341e;
  }
  .space_r {
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 162px;
    background: #ff7b33;
  }
  .left {
    .amount {
      color: #606266;
      font-size: 14px;
    }
    .price {
      font-size: 24px;
      color: #f8341e;
      margin-top: 12px;
    }
  }
  .right {
    margin-left: 68px;
    color: #606266;
    font-size: 16px;
    padding-top: 20px;
  }
  .right_r {
    margin-left: 68px;
    font-size: 16px;
    color: #606266;
  }
  .surewithdrawal {
    margin-top: 60px;
    .withdrawal_input {
      margin-bottom: 18px;
      display: flex;
      justify-content: center;
      ::v-deep .el-input {
        width: 236px;
        height: 40px;
      }
      .withdrawal_input_text {
        width: 70px;
        line-height: 40px;
        font-size: 14px;
        color: #606266;
      }
      .yuan {
        line-height: 40px;
        font-size: 16px;
        margin-left: 5px;
      }
    }
    .submit {
      text-align: center;
    }
  }
  .explain {
    width: 451px;
    height: 158px;
    background: #f5f5f5;
    border-radius: 8px;
    margin: 30px auto 0;
    padding-left: 27px;
    padding-top: 24px;
    box-sizing: border-box;
    font-size: 14px;
    color: #606266;
    div {
      line-height: 30px;
    }
  }
}
</style>
